<template>
  <div>
    <v-card flat class="pa-3 mt-2">
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">UNPAID SERVICE</h4></v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:top>
            <v-toolbar flat>
              <span class="subtitle-1">Total Balance:  {{total}}</span>
              <v-divider class="mx-4" inset vertical></v-divider>
              <span class="headline font-weight-light ">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            </v-toolbar>
          </template>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">FSC#</th>
              <th class="text-center text-uppercase">Name</th>
              <th class="text-center text-uppercase">Date of Service</th>
              <th class="text-center text-uppercase">Date of Internment</th>
              <th class="text-center text-uppercase">Mode of Service</th>
              <th class="text-center text-uppercase">Details</th>
              <th class="text-center text-uppercase">Casket Type</th>
              <th class="text-center text-uppercase">Total Payables</th>
              <th class="text-center text-uppercase">Pay</th>
              <th class="text-center text-uppercase">HO Share</th>
              <th class="text-center text-uppercase">Guaranted</th>
              <th class="text-center text-uppercase">Guaranted Details</th>
              <th class="text-center text-uppercase">Total Balance</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in list_of_deceased_items" :key="item.id">
              <td>{{ item.fsc_no }}</td>
              <td class="text-center">
                {{ item.name }}
              </td>
              <td class="text-center">
                {{ item.date_of_service }}
              </td>
              <td class="text-center">
                {{ item.date_of_internment }}
              </td>
              <td class="text-center">
                {{ item.mode_of_service}}
              </td>
              <td class="text-center">
                {{
                (item.damayan_branch===null||item.damayan_branch==='null'?'':item.damayan_branch+'
                '+item.maf_no)
                +(item.kaagapay===null||item.kaagapay==='null'?'':item.kaagapay.last_name+',
                '+item.kaagapay.first_name)}}
              </td>
              <td class="text-center">
                {{ item.casket_type}}
              </td>
              <td class="text-center">
                <v-chip color="info" v-if="item.total!=''">{{ item.total}}</v-chip>
              </td>
              <td class="text-center">
                <v-chip color="warning" v-if="item.payments!=''">{{ item.payments}}</v-chip>
              </td>
              <td class="text-center">
                <v-chip v-if="item.ho_share!=''">{{ item.ho_share}}</v-chip>
              </td>
              <td class="text-center">
                <v-chip v-if="item.guaranted_letter!=''">{{ item.guaranted_letter}}</v-chip>
              </td>
              <td class="text-center">
                {{ item.guaranted_letter_details}}
              </td>
              <td class="text-center">
                <v-chip color="error" v-if="item.balance!=''">{{ item.balance}}</v-chip>
              </td>
              <td>
                <v-icon
                  v-if="position==='ADMIN'||position==='ADMIN SPECIAL'"
                  class="mr-2"
                  color="info"
                  @click="edit_branch_address(item)"
                >
                  {{icons.mdiPencil}}
                </v-icon>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="is_can_edit" persistent max-width="80%">
      <v-card>
        <v-form ref="form" class="multi-col-validation">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">EDIT BRANCH ADDRESS</h4>
            </v-toolbar-title>
          </v-toolbar>

          <v-layout row wrap class="align-center mx-2 mt-5">
            <v-flex xs12 md12 class="mt-4">
              <v-text-field
                class="mx-2"
                v-model="date"
                dense
                label="Date"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
              <v-text-field
                class="mx-2"
                v-model="guaranted_amount"
                dense
                label="Guaranted Amount"
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
              ></v-text-field>

              <v-text-field
                class="mx-2"
                v-model="description"
                dense
                label="Description"
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"
              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                class="w-full"
                color="primary"
                @click="done_edit"
                v-if="!saving_data"
              >
                Done
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-btn color="error" @click="is_can_edit = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiPrinter, mdiPencil} from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      month_of: '',
      month_of_items: [],

      is_can_view: false,
      is_can_edit: false,
      guaranted_amount: '0',
      date: '',
      description: '',
      selected_data: {},

      list_of_deceased_items: [],
      is_deleting: false,
      total: 0,
      ho_share: 0,
      total_payables: 0,
      total_paid: 0,
      total_guaranted: 0,
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPrinter,
          mdiPencil,
        },
      }
    },
    created() {
      this.get_unpaid_transaction_month({
        branch_id: this.branch_id,
      })
        .then(response => {
          this.month_of_items = response.data
          this.month_of_items.splice(0, 0, {
            id: this.month_of_items[0].year + 1,
            month_of: 'All'
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return initialState()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id', 'branch', 'branch_address', 'branch_contact_no', 'position']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months_history', ['get_transaction_month_history']),
      ...mapActions('deceased_client', ['get_unpaid_deceased', 'get_unpaid_transaction_month', 'register_guaranted_letter']),
      edit_branch_address(item) {
        this.is_can_edit = true
        this.selected_data = item
        this.guaranted_amount = '0'
      },
      done_edit() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var cat = this.category_id
          const data = new FormData()
          data.append('deceased_client_id', this.selected_data.id);
          data.append('branch_id', this.selected_data.branch_id);
          data.append('amount', this.guaranted_amount);
          data.append('description', this.description.toUpperCase());
          data.append('date', this.date);
          this.register_guaranted_letter(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.is_can_edit = false
              this.monthly_remittances()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      monthly_remittances() {
        const data = new FormData()
        data.append('branch_id', this.branch_id)
        data.append('month_of', this.month_of)
        this.get_unpaid_deceased(data)
          .then(response => {
            this.list_of_deceased_items = response.data[0].data
            this.total = response.data[0].total
            this.ho_share = response.data[0].ho_share
            this.total_payables = response.data[0].total_payables
            this.total_paid = response.data[0].total_paid
            this.total_guaranted = response.data[0].total_guaranted
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      print_data() {
        var imgData = this.company_logo
        var payments_array = []
        var data_array = []
        var data = 'UNPAID SERVICE'
        var month = this.month_of
        data_array = [60, 150, 60, 60, 80, 40, 40, 40, 40, 40, 100, 60]
        payments_array.push(
          [
            {text: 'FSC #', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Date of Service', alignment: 'center', style: 'label'},
            {text: 'Date of Interment', alignment: 'center', style: 'label'},
            {text: 'Mode of Service', alignment: 'center', style: 'label'},
            {text: 'Casket Type', alignment: 'center', style: 'label'},
            {text: 'Total Payables', alignment: 'center', style: 'label'},
            {text: 'Pay', alignment: 'center', style: 'label'},
            {text: 'HO Share', alignment: 'center', style: 'label'},
            {text: 'Guranted', alignment: 'center', style: 'label'},
            {text: 'Guranted Details', alignment: 'center', style: 'label'},
            {text: 'Total Balance', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_deceased_items.length > 0) {
          let ho_shares = this.ho_share
          let total_payabless = this.total_payables
          let total_balances = this.total
          let total_paid = this.total_paid
          let total_guaranted = this.total_guaranted
          this.list_of_deceased_items.forEach(function (item, index, payment) {
            payments_array.push(
              [
                {text: item.fsc_no, alignment: 'left'},
                {
                  text: item.name,
                  alignment: 'left'
                },
                {text: item.date_of_service, alignment: 'left'},
                {text: item.date_of_internment, alignment: 'left'},
                {text: item.mode_of_service, alignment: 'left'},
                {text: item.casket_type, alignment: 'left'},
                {text: item.total, alignment: 'left', color: 'blue'},
                {text: item.payments, alignment: 'left', color: 'orange'},
                {text: item.ho_share, alignment: 'left'},
                {text: item.guaranted_letter, alignment: 'left'},
                {text: item.guaranted_letter_details, alignment: 'left'},
                {text: item.balance, alignment: 'left', color: 'red'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Total:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_payabless,
                    alignment: 'left',
                    border: [false, false, false, false],
                  }, {
                  text: total_paid,
                  alignment: 'left',
                  border: [false, false, false, false],
                },
                  {
                    text: ho_shares,
                    alignment: 'left',
                    border: [false, false, false, false],
                  }, {
                  text: total_guaranted,
                  alignment: 'left',
                  border: [false, false, false, false],
                },
                  {
                    text: total_balances,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }


        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936
          },
          pageOrientation: 'landscape',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF SERVICES ' + data, style: 'title'},
            '============================================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: data_array,
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Chapel Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 10
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    },
  }
</script>
